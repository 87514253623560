import { AppProps } from 'next/dist/next-server/lib/router/router';
import Head from 'next/head';
import { QueryClient, QueryClientProvider } from 'react-query';
import { addGlobalCss } from 'stitches.config';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 30_000,
    },
  },
});

if (process.env.NEXT_PUBLIC_ENABLE_MOCKS === 'true') {
  const mocksReadyPromise = import('../mocks').then(({ initializeMocks }) =>
    initializeMocks(),
  );
  if (typeof window !== 'undefined') {
    // this allows any part of the code to wait for mocks to be ready before making any requests
    window.__mocksReady = mocksReadyPromise;
  }
}

const App: React.FC<AppProps> = ({ Component, pageProps }) => {
  addGlobalCss();

  return (
    <>
      <Head>
        <title>Paperpal</title>
      </Head>
      <QueryClientProvider client={queryClient}>
        <Component {...pageProps} />
      </QueryClientProvider>
    </>
  );
};

export default App;
